import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "invalid-feedback d-block"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _renderSlot(_ctx.$slots, "default", {
      errors: _ctx.errors,
      invalid: _ctx.invalid
    }),
    _renderSlot(_ctx.$slots, "errors", {}, () => [
      (_ctx.invalid && _ctx.showErrors)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.errors[0]), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}